export const businessData = {
  banking: {
    name: "Banking",
    subdomains: {
      "customer portal": {
        name: "Customer Portal",
        functions: {
          auth: "Authentication",
          "account overview": "Account overview",
          "account details": "Account details",
          "onboarding": "Onboarding",
          "payee management":"Payee management",
          "transaction management": "Transaction management",
          "personal finance management": "Personal Finance management",
          "profile management": "Profile management",
          notifications: "Notifications",
          "da reporting": "DA reporting",
          "third party integration": "Third party integration",
        },
      },
      payments: {
        name: "Payments",
        functions: {
          "digital payments wallet": "Digital payments wallet",
          "real time processing": "Real time processing",
          "cross border payments": "Cross border payments",
          "fraud detection": "Fraud detection",
          "mobile payments": "Mobile payments",
        },
      },
      "core banking": {
        name: "Core Banking",
        functions: {
          "real time processing": "Real time processing",
          "automated workflows": "Automated workflows",
          "bank management ":"Bank management",
          bpm: "BPM",
          "regulatory compliance": "Regulatory compliance",
        },
      },
    },
  },
};


